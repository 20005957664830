// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================
import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Heading, Text, Image, useThemeUI } from 'theme-ui'
import Link from 'gatsby-link'

// ?===================================
// ?===  Local Components Imports  ====
// ?===================================

// ?===================================
// ?====  Local Function Imports  =====
// ?===================================

// ?===================================
// ?==========  Component  ============
// ?===================================

export default function NotFound() {
  // ?==========================
  // ?===== GraphQl Query  =====
  // ?==========================

  const data = useStaticQuery(graphql`
    query notFoundQuery {
      businessData {
        avatar {
          imageBaseUrl
          imagePrefix
        }
        cover {
          imagePrefix
          imageBaseUrl
        }
        name
      }
    }
  `)

  // ?==========================
  // ?====== Destructing  ======
  // ?==========================

  const { avatar, cover, name } = data.businessData

  // ?==============================
  // ?=====  Variable Creation  ====
  // ?==============================
  // grabs the theme ui object for use
  const themeContext = useThemeUI().theme
  const logo = avatar.imageBaseUrl + '/' + avatar.imagePrefix
  const coverPhoto = cover.imageBaseUrl + '/' + cover.imagePrefix
  const variantName = 'notFound'

  // ?============================================
  // ?====== Dynamic Font Loading Operators ======
  // ?============================================

  const googleFamilies = themeContext.fonts.googleFonts ? themeContext.fonts.googleFonts : ['Roboto'] // without passing anything it breaks

  const customFamilies = themeContext.fonts.customFamilies ? themeContext.fonts.customFamilies : ['']
  const customUrls = themeContext.fonts.customUrls ? themeContext.fonts.customUrls : ['']

  // ?=============================================
  // ?==============   Functions   ================
  // ?=============================================

  // ?============================================
  // ?===========   ON/OFF MOUNTING   ============
  // ?============================================

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const WebFont = require('webfontloader') // uses the webfont loadder library to dynamically load the right fonts
      WebFont.load({
        google: {
          families: googleFamilies
        },
        custom: {
          families: customFamilies,
          urls: customUrls
        }
      })
    }

    return () => {}
  }, [])

  // ?=========================
  // ?===== JSX rendering =====
  // ?=========================

  return (
    <Box variant={`${variantName}.container`}>
      <Box variant={`${variantName}.coverContainer`}>
        <Image variant={`${variantName}.cover`} src={coverPhoto} alt="Branding Trademark" />
      </Box>
      <Box variant={`${variantName}.content`}>
        <Image variant={`${variantName}.logo`} src={logo} alt="Branding Trademark" />
        <Heading as="h1" variant={`${variantName}.title`}>
          404
        </Heading>
        <Heading as="h2" variant={`${variantName}.subtitle`}>
          Error @ {name}
        </Heading>
        <Text as="p" variant={`${variantName}.text`}>
          The page you are looking for no longer exists or could not be found.
        </Text>
        <Box variant={`${variantName}.buttonContainer`}>
          <Link to="/">Return To Homepage</Link>
        </Box>
      </Box>
    </Box>
  )
}
